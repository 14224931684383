
import { defineComponent, ref, onMounted, reactive } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import affTicket from "@/views/still/deploy/affTicket.vue";
import chat from "@/views/still/chat/chat.vue";

import mAxiosApi from "@/api";

import { useI18n } from "vue-i18n";


export default defineComponent({
  name: "Liste des commandes en cours",
  components: {
    Datatable,
    SearchAndFilter,
    affTicket,
    chat
  },

  emits: ["refreshRouter"],

  setup(props, { emit }) {
    const { t } = useI18n();
    //moment.default.locale("fr");
    const state = reactive({
      loaderEnabled: true,
      myList: [],
      drawer: ref(false),
      drawerImport: ref(false),
      sourcePDF: "",
      mFact: "",
      initialMyList: [],
      loadingDatatable: 0,
      search: "",
      ticketSelect: {},
      valTicket: {},
      selTicket : 0,
      topChat : 95,
      hauteurChat: 124,
    });
    const tableHeader = ref([
      {
        name: "Ticket",
        key: "tic_seq",
        sortable: true,
      },
      {
        name: "Création",
        key: "tic_d_creation",
        sortable: true,
      },
      {
        name: "Statut",
        key: "tic_c_status",
        sortable: true,
      },
      {
        name: "Actions",
        key: "tic_knum_societe",
        sortable: true,
      },
    ]);

    const tableFilters = ref([
      {
        name: "Statut",
        key: "tic_c_status",
        label: "tic_c_status",
      },
      {
        name: "Demandeur",
        key: "con_prenom_nom",
        label: "con_prenom_nom",
      },
    ]);

    const ExcelFormat = ref({
      "Ticket": "tic_seq",
      "Création": "tic_d_creation",  
      "Libellé du ticket": "tic_c_label",
      "Demandeur": "con_prenom_nom",
      "Statut": "tic_c_status",
    });

    const formatDate = (d) => {
      return moment.default(d).format("DD.MM.YYYY");
    };

    const test_3_month = (d) => {
      const currentDate = moment.default().add(3, 'M');
      return moment.default(d) > currentDate;
    }

    const refreshRouter = () => {
      emit("refreshRouter" , 1);
    }

    const passLng = (d) => {
      state.myList.forEach(
        function(item: any){
          if (item.displayrow == 1) {
            item.displayrow = 2
          } else {
            item.displayrow = 1
          }
        }
      );
    }

    const changeLabel = async (cont) => {
      mAxiosApi
        .post("updateContLabel", cont)
        .then(({ data }) => {
          // console.log(data["results"]);
        })
        .catch((err) => {
          console.error(err);
        });
    };


    const affDetail = async (data) => {
      
      state.selTicket = data.tic_seq;

      let myList = await mAxiosApi.prototype.getAxios("/getTicket/" + data.tic_seq);
      myList.results.tic_staging.forEach((el) => {
        el.fichiers = JSON.parse(el.fichiers);
      })
      state.valTicket = myList.results;    
      state.drawer = true;
    };

    const affSUivant = async () => {
      if (posIndex()<state.myList.length) {
        state.ticketSelect = state.myList[posIndex()+1];
        // await affDetail(state.myList[posIndex()+1]);
      }
    };

    const affPrecedent = async () => {
      if (posIndex()>0) {
        state.ticketSelect = state.myList[posIndex()-1];
        // await affDetail(state.myList[posIndex()-1]);
      }
    };

    const posIndex = () => {
      const mlContrat: any = state.myList;
      const indexCont = mlContrat.findIndex(function (data: any) {
        const mContrat: any = state.ticketSelect;
        return data.fsh_contract_number == mContrat.fsh_contract_number;
      });
      return indexCont;
    };

    const imgConst = (idConst) => {
      const storedConst =  localStorage.getItem('storedConst');
      const storedConstJson = storedConst ? JSON.parse(storedConst) : '';
      const socConst: any = storedConstJson.filter(function (data: any) {
        return data.soc_seq == idConst;
      })[0]
      if (socConst) {
        return  `data:image/jpg;base64,${socConst.soc_logo}`;
      } else {
        return ''
      }
      
    };

    const changeContrat = (ev) => {
      // console.log('Passe ici ', ev);

      const index = state.myList.findIndex(
        (object: any) => object.code === ev.code
      );
      const myList: any = state.myList;
      myList[index]['cont_x_archive'] = ev.cont_x_archive;
      // console.log('INDEX', index);

    }

    const styleImgStatus = (data) => {
      if (data.tic_c_status == t("Traité")) {
        return "media/icons/duotune/arrows/arr085.svg";
      } else {
        return "media/icons/duotune/general/gen012.svg";
        
      }
    };

    const styleBtnStatus = (data) => {
      if (data.tic_c_status == t("Traité")) {
        return "btn-icon-success bg-white";
      } else {
         return "btn-icon-dark bg-white svg-icon svg-icon-sm-6 ";
      }
    };

    onMounted(async () => {
      MenuComponent.reinitialization();

      let myList = await mAxiosApi.prototype.getAxios("/getTickets");
      state.initialMyList = myList.results;
      state.myList = myList.results;      
      // console.log(state.myList);
      state.loaderEnabled = false;
    });

    return {
      tableHeader,
      state,
      formatDate,
      test_3_month,
      affDetail,
      tableFilters,
      ExcelFormat,
      imgConst,
      passLng,
      changeLabel,
      affSUivant,
      affPrecedent,
      posIndex,
      refreshRouter,
      changeContrat,
      styleImgStatus,
      styleBtnStatus
    };
  },
});
